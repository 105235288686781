export default
    {
        "equipment": [
            //HELMS
            {
                name: "Splint Helm",
                id: "lhu001",
                armor: 1,
                value: 5,
                description: "A Splint helmet.",
                slot: "helm",
                image: require("../../assets/images/armorimages/helms/splinthelm.png"),
                type: "equipment",
                levelRange: 1,
            },
            {
                name: "Chainmail Helm",
                id: "ihu001",
                armor: 2,
                value: 10,
                description: "An Chainmail helmet.",
                slot: "helm",
                image: require("../../assets/images/armorimages/helms/chainmailhelm.png"),
                type: "equipment",
                levelRange: 2
                ,
            },
            {
                name: "Scalemail Helm",
                id: "shu001",
                armor: 4,
                value: 25,
                description: "A Scalemail helmet.",
                slot: "helm",
                image: require("../../assets/images/armorimages/helms/scalemailhelm.png"),
                type: "equipment",
                levelRange: 3,
            },
            {
                name: "Iron Helm",
                id: "ibhu001",
                armor: 5,
                value: 40,
                description: "An iron helmet.",
                slot: "helm",
                image: require("../../assets/images/armorimages/helms/ironhelm.png"),
                type: "equipment",
                levelRange: 3,
            },
            {
                name: "Steel Helm",
                id: "bhu001",
                armor: 6,
                value: 55,
                description: "A Steel helmet.",
                slot: "helm",
                image: require("../../assets/images/armorimages/helms/steelhelm.png"),
                type: "equipment",
                levelRange: 4,
            },
            {
                name: "Mithril Helm",
                id: "mhu001",
                armor: 8,
                value: 100,
                description: "A mithril helmet.",
                slot: "helm",
                image: require("../../assets/images/armorimages/helms/steelhelm.png"),
                type: "equipment",
                levelRange: 5,
            },
            {
                name: "Adamant Helm",
                id: "ahu001",
                armor: 11,
                value: 260,
                description: "An adamant helmet.",
                slot: "helm",
                image: require("../../assets/images/armorimages/helms/steelhelm.png"),
                type: "equipment",
                levelRange: 6,
            },
            {
                name: "Crystal Helm",
                id: "chu001",
                armor: 15,
                value: 500,
                description: "A crystal helmet.",
                slot: "helm",
                image: require("../../assets/images/armorimages/helms/steelhelm.png"),
                type: "equipment",
                levelRange: 7,
            },
            //CHESTS
            {
                name: "Leather Vest",
                id: "lvcu001",
                armor: 1,
                value: 5,
                description: "A sturdy leather vest.",
                slot: "chest",
                image: require("../../assets/images/armorimages/chests/leathervest.png"),
                type: "equipment",
                levelRange: 0,
            },
            {
                name: "Splint Chestpiece",
                id: "lcu001",
                armor: 2,
                value: 15,
                description: "A Splint chestpiece.",
                slot: "chest",
                image: require("../../assets/images/armorimages/chests/splintchestpiece.png"),
                type: "equipment",
                levelRange: 1,
            },
            {
                name: "Chainmail Chestpiece",
                id: "icu001",
                armor: 4,
                value: 25,
                description: "An Chainmail chestpiece.",
                slot: "chest",
                image: require("../../assets/images/armorimages/chests/chainmailshirt.png"),
                type: "equipment",
                levelRange: 2
                ,
            },
            {
                name: "Scalemail Chestpiece",
                id: "scu001",
                armor: 8,
                value: 50,
                description: "A Scalemail chestpiece.",
                slot: "chest",
                image: require("../../assets/images/armorimages/chests/scalemailchestpiece.png"),
                type: "equipment",
                levelRange: 3,
            },
            {
                name: "Iron Chestpiece",
                id: "iscu001",
                armor: 9,
                value: 75,
                description: "A Scalemail chestpiece.",
                slot: "chest",
                image: require("../../assets/images/armorimages/chests/ironchestpiece.png"),
                type: "equipment",
                levelRange: 3,
            },
            {
                name: "Steel Chestpiece",
                id: "bcu001",
                armor: 11,
                value: 110,
                description: "A Steel chestpiece.",
                slot: "chest",
                image: require("../../assets/images/armorimages/chests/steelchestpiece.png"),
                type: "equipment",
                levelRange: 4,
            },
            {
                name: "Mithril Chestpiece",
                id: "mcu001",
                armor: 14,
                value: 250,
                description: "A mithril chestpiece.",
                slot: "chest",
                image: require("../../assets/images/armorimages/chests/steelchestpiece.png"),
                type: "equipment",
                levelRange: 5,
            },
            {
                name: "Adamant Chestpiece",
                id: "acu001",
                armor: 16,
                value: 515,
                description: "An adamant chestpiece.",
                slot: "chest",
                image: require("../../assets/images/armorimages/chests/steelchestpiece.png"),
                type: "equipment",
                levelRange: 6,
            },
            {
                name: "Crystal Chestpiece",
                id: "ccu001",
                armor: 20,
                value: 1500,
                description: "A crystal chestpiece.",
                slot: "chest",
                image: require("../../assets/images/armorimages/chests/steelchestpiece.png"),
                type: "equipment",
                levelRange: 7,
            },
            //GAUNTLETS
            {
                name: "Leather Gloves",
                id: "hlshu001",
                armor: .5,
                value: 2,
                description: "Sturdy leather gloves.",
                slot: "hands",
                image: require("../../assets/images/armorimages/hands/leathergloves.png"),
                type: "equipment",
                levelRange: 0,
            },
            {
                name: "Splint Gauntlets",
                id: "hlhu001",
                armor: 1,
                value: 5,
                description: "Splint gloves.",
                slot: "hands",
                image: require("../../assets/images/armorimages/hands/splintgauntlets.png"),
                type: "equipment",
                levelRange: 1,
            },
            {
                name: "Chainmail Gauntlets",
                id: "hihu001",
                armor: 2,
                value: 10,
                description: "Chainmail gloves.",
                slot: "hands",
                image: require("../../assets/images/armorimages/hands/chainmailgauntlets.png"),
                type: "equipment",
                levelRange: 2
                ,
            },
            {
                name: "Scalemail Gauntlets",
                id: "hshu001",
                armor: 3,
                value: 25,
                description: "Scalemail gloves.",
                slot: "hands",
                image: require("../../assets/images/armorimages/hands/scalemailgauntlets.png"),
                type: "equipment",
                levelRange: 3,
            },
            {
                name: "Iron Gauntlets",
                id: "hibhu001",
                armor: 4,
                value: 40,
                description: "Iron gauntlets.",
                slot: "hands",
                image: require("../../assets/images/armorimages/hands/irongauntlets.png"),
                type: "equipment",
                levelRange: 3,
            },
            {
                name: "Steel Gauntlets",
                id: "hbhu001",
                armor: 5,
                value: 55,
                description: "Steel gauntlets.",
                slot: "hands",
                image: require("../../assets/images/armorimages/hands/steelgauntlets.png"),
                type: "equipment",
                levelRange: 4,
            },
            {
                name: "Mithril Gauntlets",
                id: "hmhu001",
                armor: 6,
                value: 100,
                description: "A mithril helmet.",
                slot: "hands",
                image: require("../../assets/images/armorimages/hands/steelgauntlets.png"),
                type: "equipment",
                levelRange: 5,
            },
            {
                name: "Adamant Gauntlets",
                id: "hahu001",
                armor: 9,
                value: 260,
                description: "An adamant helmet.",
                slot: "hands",
                image: require("../../assets/images/armorimages/hands/steelgauntlets.png"),
                type: "equipment",
                levelRange: 6,
            },
            {
                name: "Crystal Gauntlets",
                id: "hchu001",
                armor: 11,
                value: 500,
                description: "A crystal helmet.",
                slot: "hands",
                image: require("../../assets/images/armorimages/hands/steelgauntlets.png"),
                type: "equipment",
                levelRange: 7,
            },
            //NECK SLOTS
            {
                name: "Silver Necklace",
                id: "sggnu001",
                health: 5,
                value: 15,
                description: "A silver necklace.",
                slot: "neck",
                image: require("../../assets/images/gearimages/necklaces/silvernecklace.png"),
                type: "equipment",
                levelRange: 8,
            },
            {
                name: "Gold Necklace",
                id: "gggnu001",
                health: 10,
                value: 35,
                description: "A gold necklace.",
                slot: "neck",
                image: require("../../assets/images/gearimages/necklaces/goldnecklace.png"),
                type: "equipment",
                levelRange: 8,
            },
            {
                name: "Ruby Necklace",
                id: "rggnu001",
                health: 15,
                value: 60,
                description: "A ruby necklace.",
                slot: "neck",
                image: require("../../assets/images/gearimages/necklaces/rubynecklace.png"),
                type: "equipment",
                levelRange: 8,
            },
            {
                name: "Emerald Necklace",
                id: "eggnu001",
                health: 25,
                value: 120,
                description: "An emerald necklace.",
                slot: "neck",
                image: require("../../assets/images/gearimages/necklaces/emeraldnecklace.png"),
                type: "equipment",
                levelRange: 8,
            },
            {
                name: "Onyx Necklace",
                id: "oggnu001",
                health: 45,
                value: 250,
                description: "An onyx necklace.",
                slot: "neck",
                image: require("../../assets/images/gearimages/necklaces/onyxnecklace.png"),
                type: "equipment",
                levelRange: 8,
            },
            {
                name: "Diamond Necklace",
                id: "dggnu001",
                health: 80,
                value: 900,
                description: "A diamond necklace.",
                slot: "neck",
                image: require("../../assets/images/gearimages/necklaces/diamondnecklace.png"),
                type: "equipment",
                levelRange: 8,
            },
            //BAGS
            {
                name: "Ore Collection Bag",
                id: "ocb001",
                perk: "oreCollection",
                value: 25,
                description: "Allows collection of ore.",
                slot: "shoulder",
                image: require("../../assets/images/gearimages/necklaces/diamondnecklace.png"),
                type: "equipment",
                levelRange: 8,
            },
            {
                name: "Herb Collection Bag",
                id: "hcb001",
                perk: "herbCollection",
                value: 50,
                description: "Allows collection of herbs.",
                slot: "shoulder",
                image: require("../../assets/images/gearimages/necklaces/diamondnecklace.png"),
                type: "equipment",
                levelRange: 8,
            },
            {
                name: "Runestone Collection Bag",
                id: "rcb001",
                perk: "runestoneCollection",
                value: 25,
                description: "Allows collection of runestones.",
                slot: "shoulder",
                image: require("../../assets/images/gearimages/necklaces/diamondnecklace.png"),
                type: "equipment",
                levelRange: 8,
            },
            //MAIN HANDS
            {
                name: "Iron Dagger",
                id: "mhid001",
                damage: 2,
                value: 2,
                description: "An iron dagger.",
                slot: "mainhand",
                image: require("../../assets/images/weaponimages/daggers/irondagger.png"),
                type: "equipment",
                levelRange: 1,
            },
            {
                name: "Iron Short Sword",
                id: "mhiss001",
                damage: 3,
                value: 5,
                description: "An iron short sword.",
                slot: "mainhand",
                image: require("../../assets/images/weaponimages/swords/ironshortsword.png"),
                type: "equipment",
                levelRange: 1,
            },
            {
                name: "Steel Short Sword",
                id: "mhsss001",
                damage: 6,
                value: 15,
                description: "A steel short sword.",
                slot: "mainhand",
                image: require("../../assets/images/weaponimages/swords/steelshortsword.png"),
                type: "equipment",
                levelRange: 2
                ,
            },
            {
                name: "Mithril Short Sword",
                id: "mhmss001",
                damage: 12,
                value: 75,
                description: "Mithril short sword.",
                slot: "mainhand",
                image: require("../../assets/images/weaponimages/swords/mithrilshortsword.png"),
                type: "equipment",
                levelRange: 2,
            },
            {
                name: "Iron Longsword",
                id: "mhils001",
                damage: 5,
                value: 10,
                description: "An iron longsword.",
                slot: "mainhand",
                image: require("../../assets/images/weaponimages/swords/ironlongsword.png"),
                type: "equipment",
                levelRange: 2
                ,
            },
            {
                name: "Steel Longsword",
                id: "mhsls001",
                damage: 12,
                value: 25,
                description: "A steel longsword.",
                slot: "mainhand",
                image: require("../../assets/images/weaponimages/swords/steellongsword.png"),
                type: "equipment",
                levelRange: 3,
            },
            {
                name: "Black Longsword",
                id: "mhbls001",
                damage: 18,
                value: 55,
                description: "A black steel longsword.",
                slot: "mainhand",
                image: require("../../assets/images/weaponimages/swords/blacklongsword.png"),
                type: "equipment",
                levelRange: 4,
            },
            {
                name: "Mithril Longsword",
                id: "mhmls001",
                damage: 24,
                value: 100,
                description: "Mithril longsword.",
                slot: "mainhand",
                image: require("../../assets/images/weaponimages/swords/mithrillongsword.png"),
                type: "equipment",
                levelRange: 5,
            },
            {
                name: "Adamant Longsword",
                id: "mhals001",
                damage: 30,
                value: 260,
                description: "Adamant longsword.",
                slot: "mainhand",
                image: require("../../assets/images/weaponimages/swords/adamantitelongsword.png"),
                type: "equipment",
                levelRange: 6,
            },
            {
                name: "Crystal Longsword",
                id: "mhcls001",
                damage: 38,
                value: 500,
                description: "Crystal longsword.",
                slot: "mainhand",
                image: require("../../assets/images/weaponimages/swords/crystallongsword.png"),
                type: "equipment",
                levelRange: 7,
            },
            //OFFHANDS
            {
                name: "Wooden Round Shield",
                id: "wrs001",
                armor: 1,
                value: 5,
                description: "A round wooden shield.",
                slot: "offhand",
                image: require("../../assets/images/weaponimages/shields/woodroundshield.png"),
                type: "equipment",
                levelRange: 1,
            },
            {
                name: "Wooden Kite Shield",
                id: "wks001",
                armor: 2,
                value: 15,
                description: "A triangular wooden shield.",
                slot: "offhand",
                image: require("../../assets/images/weaponimages/shields/woodkiteshield.png"),
                type: "equipment",
                levelRange: 2
                ,
            },
            {
                name: "Wooden Tower Shield",
                id: "wts001",
                armor: 5,
                value: 55,
                description: "A large, rectangular wooden shield.",
                slot: "offhand",
                image: require("../../assets/images/weaponimages/shields/woodtowershield.png"),
                type: "equipment",
                levelRange: 3,
            },
            //GREAVES
            {
                name: "Splint Greaves",
                id: "lgu001",
                armor: 1,
                value: 5,
                description: "Splint greaves.",
                slot: "legs",
                image: require("../../assets/images/horn-128x128-1512053.png"),
                type: "equipment",
                levelRange: 1,
            },
            {
                name: "Chainmail Greaves",
                id: "igu001",
                armor: 2,
                value: 10,
                description: "Chainmail greaves.",
                slot: "legs",
                image: require("../../assets/images/horn-128x128-1512053.png"),
                type: "equipment",
                levelRange: 2
                ,
            },
            {
                name: "Scalemail Greaves",
                id: "sgu001",
                armor: 4,
                value: 25,
                description: "Scalemail greaves.",
                slot: "legs",
                image: require("../../assets/images/horn-128x128-1512053.png"),
                type: "equipment",
                levelRange: 3,
            },
            {
                name: "Steel Greaves",
                id: "bgu001",
                armor: 6,
                value: 55,
                description: "Steel greaves.",
                slot: "legs",
                image: require("../../assets/images/horn-128x128-1512053.png"),
                type: "equipment",
                levelRange: 4,
            },
            {
                name: "Mithril Greaves",
                id: "mgu001",
                armor: 8,
                value: 100,
                description: "Mithril greaves.",
                slot: "legs",
                image: require("../../assets/images/horn-128x128-1512053.png"),
                type: "equipment",
                levelRange: 5,
            },
            {
                name: "Adamant Greaves",
                id: "agu001",
                armor: 11,
                value: 260,
                description: "Adamant greaves.",
                slot: "legs",
                image: require("../../assets/images/horn-128x128-1512053.png"),
                type: "equipment",
                levelRange: 6,
            },
            {
                name: "Crystal Greaves",
                id: "cgu001",
                armor: 15,
                value: 500,
                description: "Crystal greaves.",
                slot: "legs",
                image: require("../../assets/images/horn-128x128-1512053.png"),
                type: "equipment",
                levelRange: 7,
            },
            //SABATONS
            {
                name: "Leather Boots",
                id: "lfsu001",
                armor: 1,
                value: 2,
                description: "Leather boots.",
                slot: "feet",
                image: require("../../assets/images/armorimages/feet/leatherboots.png"),
                type: "equipment",
                levelRange: 0,
            },
            {
                name: "Splint Sabatons",
                id: "lsu001",
                armor: 2,
                value: 5,
                description: "Splint sabatons.",
                slot: "feet",
                image: require("../../assets/images/armorimages/feet/splintsabatons.png"),
                type: "equipment",
                levelRange: 1,
            },
            {
                name: "Chainmail Sabatons",
                id: "isu001",
                armor: 3,
                value: 10,
                description: "Chainmail sabatons.",
                slot: "feet",
                image: require("../../assets/images/armorimages/feet/chainmailsabatons.png"),
                type: "equipment",
                levelRange: 2
                ,
            },
            {
                name: "Scalemail Sabatons",
                id: "ssu001",
                armor: 5,
                value: 25,
                description: "Scalemail sabatons.",
                slot: "feet",
                image: require("../../assets/images/armorimages/feet/scalemailsabatons.png"),
                type: "equipment",
                levelRange: 3,
            },
            {
                name: "Iron Sabatons",
                id: "ssu001",
                armor: 7,
                value: 40,
                description: "Iron sabatons.",
                slot: "feet",
                image: require("../../assets/images/armorimages/feet/ironsabatons.png"),
                type: "equipment",
                levelRange: 3,
            },
            {
                name: "Steel Sabatons",
                id: "bsu001",
                armor: 9,
                value: 55,
                description: "Steel sabatons.",
                slot: "feet",
                image: require("../../assets/images/armorimages/feet/steelsabatons.png"),
                type: "equipment",
                levelRange: 4,
            },
            {
                name: "Mithril Sabatons",
                id: "msu001",
                armor: 12,
                value: 100,
                description: "Mithril sabatons.",
                slot: "feet",
                image: require("../../assets/images/armorimages/feet/steelsabatons.png"),
                type: "equipment",
                levelRange: 5,
            },
            {
                name: "Adamant Sabatons",
                id: "asu001",
                armor: 16,
                value: 260,
                description: "Adamant sabatons.",
                slot: "feet",
                image: require("../../assets/images/armorimages/feet/steelsabatons.png"),
                type: "equipment",
                levelRange: 6,
            },
            {
                name: "Crystal Sabatons",
                id: "csu001",
                armor: 20,
                value: 500,
                description: "Crystal sabatons.",
                slot: "feet",
                image: require("../../assets/images/armorimages/feet/steelsabatons.png"),
                type: "equipment",
                levelRange: 7,
            },
        ],
    }
